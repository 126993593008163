body.page-minimal {
  background: $body-bg url($public-page-bg);
  background-size: cover;
  background-attachment: fixed;

  footer {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black, 0 0 4px #000;
  }

  .public-page {
    height: 100vh;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
    justify-content: center;

    .card {
      width: 500px;

      .card-body {
        position: relative;

        .card-title {
          font-weight: bold;
        }

        .btn-float {
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: (0-($btn-float-size / 2));
          margin-right: (0-($btn-float-size / 2));
        }
      }

      @include media-breakpoint-only('xs') {
        width: calc(100% - 40px);
        left: 20px;
        margin-left: 0;
      }
    }
  }

  &.login-content,
  &.error-content {
    .card {
      .card-body {
        padding: ($card-padding-y * 2) ($card-padding-y * 2);
      }
    }
  }

  // Pulled from:
  // https://stackoverflow.com/questions/5474871/html-how-can-i-show-tooltip-only-when-ellipsis-is-activated

  .might-overflow {
    text-overflow: ellipsis;
    overflow : hidden;
    white-space: nowrap;

    &:hover {
      text-overflow: clip;
      white-space: normal;
      word-break: break-all;
    }
  }
}
