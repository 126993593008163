.chosen-container {
  .chosen-drop {
    background: $menu-bg;

    box-shadow: map-get($menu-elevation-shadow, shadow);
    border: 0;
    top: 0;
    border-radius: 2px;
  }

  .chosen-results {
    margin: 0;
    padding: 0;
    max-height: 300px;
    color: $menu-link-color;

    li {
      padding: 10px 17px;
      width: 100%;

      &.no-results {
        background: none;
      }

      &.highlighted {
        background: $menu-link-bg-hover;
        color: $menu-link-color;
      }

      &.result-selected {
        background: transparent;
        color: $body-color;
        position: relative;

        &:before {
          @include set-material-icons;
          content: 'check';
        }
      }

      &.group-result {
        &:not(:first-child) {
          border-top: 1px solid $menu-divider-bg;
        }

        color: $menu-link-color;
        font-weight: bold;
        padding: 16px 15px 6px;
        margin-top: 9px;
      }
    }
  }
}

.chosen-container-single {

  .chosen-single {
    background: none;
    color: $textfield-color;
    display: block;
    font-size: $textfield-font-size;
    line-height: $textfield-line-height;
    padding: $textfield-padding-y $textfield-padding-x;
    border-color: $textfield-border-color;
    border-radius: 0;
    border-style: solid;
    border-width: 0 0 $textfield-border-width;
    box-shadow: none;
    height: auto;

    div b {
      display: none;
    }
  }

  &.chosen-container-active .chosen-single {
    border-color: $textfield-border-color-focus;
    box-shadow: inset 0 ($textfield-border-width-focus * -1) 0 ($textfield-border-width * -1) $textfield-border-color-focus;
  }

  .chosen-search {
    &:before {
      @include set-material-icons;
      content: 'search';
    }

    input[type=text] {
      color: $textfield-color;
      line-height: $textfield-line-height;
      padding: $textfield-padding-y $textfield-padding-x;
      border: 0;
      height: auto;
      background-image: none !important;
    }
  }
}

.chosen-container-active.chosen-with-drop .chosen-single {
  border: 0;
  background: none;
}

.chosen-container-multi {

  .chosen-choices {
    background: none;
    color: $textfield-color;
    display: block;
    font-size: $textfield-font-size;
    line-height: $textfield-line-height;
    padding: $textfield-padding-y $textfield-padding-x calc(#{$textfield-padding-y} - #{$textfield-border-width});
    border-color: $textfield-border-color;
    border-radius: 0;
    border-style: solid;
    border-width: 0 0 $textfield-border-width;
    box-shadow: none;

    li {
      &.search-choice {
        background: $menu-bg;
        color: $menu-link-color;

        border-radius: 2px;
        margin: 4px 4px 0 0;
        padding: 5px 23px 5px 8px;
        border: 0;
        box-shadow: none;
        font-size: 12px;
      }

      &.search-field {
        input[type=text] {
          padding: 0;
          height: 31px;
        }
      }
    }
  }
}

.search-choice-close {
  cursor: pointer;

  &:before {
    @include set-material-icons;
    content: 'close';
  }
}


